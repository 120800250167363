body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.srm-modal-open {
  position: relative;
  /*overflow-y: hidden;*/
}

.srm-fade-in {
  animation: fadeIn 0.1s forwards
}

.srm-slide-down-fade {
  animation: slideDownFade 0.2s forwards
}

.srm-modal-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5)
}

.srm-modal-wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  overflow: auto;
  position: fixed;
  align-items: center
}

.srm-modal-content {
  margin: auto;
  height: 100vh;
  overflow: auto;
  position: relative;
  background: white;
  -webkit-font-smoothing: subpixel-antialiased
}

@media (min-width: 640px) {
  .srm-modal-content {
    height: auto;
    overflow: none;
  }
}

@media screen and (max-width: 640px) {
  .srm-modal-content {
    padding: 1rem 2rem !important;
    width: 100%;
    height: auto;
  }
}

.srm-close-icon {
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  color: gray;
  cursor: pointer;
  margin-top: 8px;
  margin-right: 8px;
  position: absolute;
}

.srm-close-icon:hover {
  color: red
}

.srm-modal-content, .modal-like {
  border-radius: 1rem;
  padding: 2rem 4rem;
  background: linear-gradient(rgba(108, 13, 68, 0.5), rgba(127, 55, 2, 0.5));
  border: 8px solid #ff6d00;
  outline: 8px solid rgba(240, 29, 151, .3);
  box-shadow: 0 10px 10px 3px rgba(0, 0, 0, .7);
}


.srm-close-icon line {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes slideDownFade {
  0% {
    opacity: 0;
    transform: translateY(-20px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

.advent {
  font-family: 'Advent Pro', sans-serif
}

#nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  text-align: center;

  & .logo {
    height: 40vmin;
    pointer-events: none;
  }  

  @media (prefers-reduced-motion: no-preference) {
    & .logo {
      animation: app-logo-spin infinite 20s linear;
    }
  }

  & .link {
    color: #61dafb;
  }  
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  font-size: calc(10px + 2vmin);
}

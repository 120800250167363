.layout {
}

select, option {
  background: black;
  color: white;
  min-width: 60px;
  padding: 12px 24px;
  border: 1px solid white;
  border-radius: 12px;
  margin-left: 12px;
  font-weight: bold;
}